<template>
  <main-card-header :title="routerTitle"></main-card-header>
  <div class="flex justify-center flex-wrap content-evenly items-center">
    <small-form
      :title="routerTitle"
      instructions="Enter the new permission name"
      :inputs="formItems"
      @resolve-form-submit="addPermission"
      :errorMessage="newPermissionError"
    ></small-form>
    <div>
      <div class="flex justify-center flex-wrap">
        <card-widget
          cardTitle="Permissions"
          icon="key"
          :cardValue="getPermissionsCards.total"
        ></card-widget>
        <card-widget></card-widget>
      </div>
      <div class="flex justify-center flex-wrap">
        <card-widget></card-widget>
        <card-widget></card-widget>
      </div>
    </div>
  </div>
  <div class="flex justify-center items-center content-evenly flex-wrap">
    <small-dropdown-form
      title="Assign User Permission"
      instructions="Assign Permission to User"
      @resolve-form-submit="assignPermissionToUser"
      :errorMessage="assignPermissionToUserError"
      :key="componentKey"
    >
      <label>Assign User:</label>
      <drop-down
        selectName="email"
        :values="getUsers"
        :onChange="resolveOnChange"
      />
      <label>To:</label>
      <drop-down
        selectName="permission"
        :values="getPermissions"
        :onChange="resolveOnChange"
      />
    </small-dropdown-form>
    <small-dropdown-form
      title="Remove User/Permission"
      instructions="Remove Permission from User"
      @resolve-form-submit="removePremissionFromUser"
      :errorMessage="removePremissionFromUserError"
      :key="componentKey"
    >
      <label>Remove User:</label>
      <drop-down
        selectName="email"
        :values="getUsers"
        :onChange="resolveOnChange"
      />
      <label>From:</label>
      <drop-down
        selectName="permission"
        :values="getPermissions"
        :onChange="resolveOnChange"
      />
    </small-dropdown-form>
  </div>
  <div class="flex justify-center items-center content-evenly flex-wrap">
    <small-dropdown-form
      title="Assign Role Permission"
      instructions="Assign Permission to Role"
      @resolve-form-submit="assignPermissionToRole"
      :errorMessage="assignPermissionToRoleError"
      :key="componentKey"
    >
      <label>Assign Permission:</label>
      <drop-down
        selectName="permission"
        :values="getPermissions"
        :onChange="resolveOnChange"
      />
      <label>To:</label>
      <drop-down
        selectName="role"
        :values="getRoles"
        :onChange="resolveOnChange"
      />
    </small-dropdown-form>
    <small-dropdown-form
      title="Remove Role/Permission"
      instructions="Remove Permission from Role"
      @resolve-form-submit="removePermissionFromRole"
      :errorMessage="removePremissionFromRoleError"
      :key="componentKey"
    >
      <label>Remove Permission:</label>
      <drop-down
        selectName="permission"
        :values="getPermissions"
        :onChange="resolveOnChange"
      />
      <label>From:</label>
      <drop-down
        selectName="role"
        :values="getRoles"
        :onChange="resolveOnChange"
      />
    </small-dropdown-form>
  </div>
  <template v-if="!isLoading">
    <dynamic-table
      v-if="!isLoading"
      title="Permissions"
      @delete="deletePermission"
    ></dynamic-table>
  </template>
  <template v-else>
    <heart-rate fast="true"></heart-rate>
  </template>
  <my-pagination
    v-if="!!getPermissionsCards.total"
    :options="options"
    v-model="page"
    :records="getPermissionsCards.total"
    :per-page="getPermissionsCards.per_page"
    @paginate="myCallback"
  >
  </my-pagination>
  <blank-paginate v-else />
</template>
<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";
import dynamicTable from "@/components/layout/SA/table/table.vue";
import smallDropdownForm from "@/components/ui/forms/smallDropdownForm.vue";
import dropDown from "@/components/ui/forms/dropDown.vue";
import smallForm from "@/components/ui/forms/smallForm.vue";
import cardWidget from "@/components/ui/SA/cardWidget.vue";

export default {
  components: {
    dynamicTable,
    smallDropdownForm,
    dropDown,
    smallForm,
    cardWidget,
    blankPaginate,
  },
  data() {
    return {
      options: {
        template: markRaw(customPaginate),
      },
      page: 1,
      componentKey: 0,
      tableHeaders: ["NAME", "ID", "CREATED", "ACTIONS"],
      formItems: [
        {
          type: "input",
          placeholder: "Permission Name",
          fieldName: "name",
        },
      ],
      roleData: {},
      permissionData: {},
      userData: {},
      localInputs: {},
      isLoading: false,
      newPermissionError: null,
      assignPermissionToUserError: null,
      assignPermissionToRoleError: null,
      removePremissionFromUserError: null,
      removePremissionFromRoleError: null,
    };
  },
  provide() {
    return { headers: this.tableHeaders };
  },
  created() {
    this.loadPermissions(this.page);
    this.loadUsers();
    this.loadRoles();
  },
  methods: {
    myCallback(e) {
      this.loadPermissions(e);
    },
    resolveOnChange(data) {
      this.localInputs[data.target.name] = data.target.value;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async loadPermissions(page) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("admin/loadPermissions", page);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async loadRoles() {
      try {
        await this.$store.dispatch("admin/loadRoles");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    async loadUsers() {
      try {
        await this.$store.dispatch("admin/loadUsers");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async deletePermission(id) {
      try {
        await this.$store.dispatch("admin/deletePermission", id);
        this.isLoading = false;
        this.$toast.error(`Permissions Deleted!`);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    async addPermission(data) {
      try {
        await this.$store.dispatch("admin/addPermission", data);
        this.newPermissionError = null;
        this.isLoading = false;
        this.$toast.success(`Permission Created!`);
      } catch (error) {
        this.error = error.message;
        this.newPermissionError = error.message;
      }
    },
    async assignPermissionToUser() {
      try {
        await this.$store.dispatch(
          "admin/assignPermissionToUser",
          this.localInputs
        );
        this.assignPermissionToUserError = null;
        this.isLoading = false;
        this.forceRerender();
        this.$toast.success(`Permission successfully assigned!`);
      } catch (error) {
        this.error = error.message;
        this.assignPermissionToUserError = error.message;
      }
    },
    async assignPermissionToRole() {
      try {
        await this.$store.dispatch(
          "admin/assignPermissionToRole",
          this.localInputs
        );
        this.assignPermissionToRoleError = null;
        this.isLoading = false;
        // this.forceRerender(); // This stops the dropdowns re-fresh after selection. Usefull when assigning lots of permission at once
        this.$toast.success(`Permission successfully assigned!`);
      } catch (error) {
        this.error = error.message;
        this.assignPermissionToRoleError = error.message;
      }
    },
    async removePremissionFromUser() {
      try {
        await this.$store.dispatch(
          "admin/removePremissionFromUser",
          this.localInputs
        );
        this.removePremissionFromUserError = null;
        this.isLoading = false;
        this.forceRerender();
        this.$toast.success(`Permission successfully removed!`);
      } catch (error) {
        this.error = error.message;
        this.removePremissionFromUserError = error.message;
      }
    },
    async removePermissionFromRole() {
      try {
        await this.$store.dispatch(
          "admin/removePermissionFromRole",
          this.localInputs
        );
        this.removePremissionFromRoleError = null;
        this.isLoading = false;
        this.forceRerender();
        this.$toast.success(`Permission successfully removed!`);
      } catch (error) {
        this.error = error.message;
        this.removePremissionFromRoleError = error.message;
      }
    },
  },
  computed: {
    routerTitle() {
      return this.$route.meta.title;
    },
    getPermissionsCards() {
      return this.$store.getters["admin/getPermissions"];
    },
    getPermissions() {
      const data = this.$store.getters["admin/getPermissions"];
      if (Object.keys(data).length !== 0) {
        data.data.forEach((item) => {
          this.permissionData[item.name] = item.name;
        });
        return this.permissionData;
      } else {
        return this.permissionData;
      }
    },
    getRoles() {
      return this.$store.getters["admin/getRoles"];
    },

    getUsers() {
      const data = this.$store.getters["admin/getUsers"];
      if (Object.keys(data).length !== 0) {
        data.data.forEach((item) => {
          this.userData[item.email] = item.email;
        });
        return this.userData;
      } else {
        return this.userData;
      }
    },
  },
};
</script>
